@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('./fonts/roboto-v18-latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('./fonts/roboto-v18-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/roboto-v18-latin-regular.woff2') format('woff2'),
    url('./fonts/roboto-v18-latin-regular.woff') format('woff'),
    url('./fonts/roboto-v18-latin-regular.ttf') format('truetype'),
    url('./fonts/roboto-v18-latin-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src: url('./fonts/roboto-v18-latin-700.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./fonts/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./fonts/roboto-v18-latin-700.woff2') format('woff2'),
    url('./fonts/roboto-v18-latin-700.woff') format('woff'),
    url('./fonts/roboto-v18-latin-700.ttf') format('truetype'),
    url('./fonts/roboto-v18-latin-700.svg#Roboto') format('svg');
}
